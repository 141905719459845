import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const discountApi = {
    _get_coupons,
    _get_vouchers,
    _add_coupon,
    _add_voucher,
    _update_coupon,
    _delete_coupon,
};



async function _get_coupons() {    

    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',      
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)      
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}

async function _get_vouchers(offset, filters, page_size) {    

    
    const requestOptions = {
        method: 'POST',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(filters)        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'vouchers/'+offset+'/'+page_size, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _add_coupon(form_data) {
    
    const requestOptions = {
        method: 'POST',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}


async function _add_voucher(form_data) {
    
    const requestOptions = {
        method: 'POST',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'vouchers', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _update_coupon(form_data, id) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete_coupon(id) {

    const requestOptions = {
        method: 'DELETE',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}