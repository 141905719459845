import {requestTimeout, handleResponse} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

var base_uri = "roles"

export const roleApi = {
    _get,
    _resources,
    _add,
    _delete,
    _update,    
};



async function _get() {    
    const requestOptions = {
        method: 'GET',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })

        
}

async function _resources() {        
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+"/resources", requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })

        
}

async function _add(data) {    
        
    const requestOptions = {
        method: 'POST',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

async function _update(data, id) {    
        
    const requestOptions = {
        method: 'PUT',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri +'/'+id, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

async function _delete(id) {    
        
    const requestOptions = {
        method: 'DELETE',        
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri +'/'+id, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

