import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const userApi = {
    _get,
    _details,
    _filter,
    _add,    
    _update,
    _delete,
};



async function _get(data, offset, limit) {    
        
    const requestOptions = {
        method: 'POST',
        headers: {    
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify(data)       
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+offset+'/'+limit, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                }); 
}

async function _details(id) {    
        
    const requestOptions = {
        method: 'GET',    
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                }); 
}


async function _filter(type, keywords) {    
        
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'user-filter/'+type+'/'+keywords, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    ;
}


async function _add(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {            
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _update(form_data, id) {
    
    const requestOptions = {
        method: 'POST',        
        headers: {
            
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {    
    const requestOptions = {
        method: 'DELETE',        
        headers: {                        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}