import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const topicApi = {
    // _get,
    _sort_items,
    _import,
    _add,
    _add_questions,
    _get_questions,
    _update,
    _delete,
};



// async function _get(id) {    
//     let token = localStorage.getItem(accessToken)
//     const requestOptions = {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + token
//         }        
//     };
//     return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
//                 .then(handleResponse)
//                 .then((response) => {                    
//                     return response
//                 });
//     ;
// }

async function _sort_items(items) {    
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',  
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)          
        },
        body: JSON.stringify({items})      
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/reorder', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                }); 
}

async function _import(form_data, topic_id) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',     
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)       
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/import/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}


async function _add(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',    
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)        
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _add_questions(form_data, topic_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',       
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)     
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/questions/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _get_questions(topic_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',       
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)     
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/questions/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _update(form_data, id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}