export const colors = {
    brand: {
        50: "#FFF2CC",
        100: "#FFF2CC",
        200: "#FFE29A",
        300: "#FFCE67",
        400: "#FFBA41",
        500: "#FF9903",
        600: "#DB7A02",
        700: "#B75E01",
        800: "#934600",
        900: "#7A3500",
    },
    accent:{
        
        50: "#FFF7CD",
        100: "#FFF7CD",
        200: "#FFED9B",
        300: "#FFE16A",
        400: "#FFD545",
        500: "#FFC107",
        600: "#DBA005",
        700: "#B78103",
        800: "#936402",
        900: "#7A4F01",
    },
    green:{
        50: "#EEFBCE",
        100: "#EEFBCE",
        200: "#D9F89F",
        300: "#B8EA6C",
        400: "#96D645",
        500: "#68BC13",
        600: "#50A10D",
        700: "#3C8709",
        800: "#2A6D06",
        900: "#1D5A03",
    },
    sidebar: '#122960',
    
    flat:{            
        tomato: "#FB4630",
        pink: "#FE445F",
        purple: "#773DE3",
        indigo: "#B28FF0",
        navy: "#3C50BC",
        blue: "#3389E1",
        aqua: "#00C3ED",
        teal: "#009988",
        green: "#77C33E",
        lime: "#A8E172",
        yellow: "#FFCC34",
        orange: "#FFB718",
        gray: "#616C78"
    },
    muted:{            
        tomato: "rgba(251,70,48, 0.25)",
        pink: "rgba(254,68,95, 0.25)",
        purple: "rgba(119,61,227, 0.25)",
        indigo: "rgba(178,141,240, 0.25)",
        navy: "rgba(60,80,188, 0.25)",
        blue: "rgba(51,137,225, 0.25)",
        aqua: "rgba(0,195,237, 0.25)",
        teal: "rgba(0,189,153, 0.25)",
        green: "rgba(119,195,62, 0.25)",
        lime: "rgba(168,225,114, 0.25)",
        yellow: "rgba(255,204,52, 0.25)",
        orange: "rgba(255,183,24, 0.25)",
        gray: "rgba(97,108,120, 0.25)",
    }      
}