import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const categoryApi = {
    _get,
    _add,
    _update,
    _delete,
};



async function _get() {        
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'categories', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _add(form_data) {    
    const requestOptions = {
        method: 'POST',        
        headers: {            
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',    
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)        
        },
        body: form_data
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'categories', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _update(form_data, id) {


    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'categories/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    
    const requestOptions = {
        method: 'DELETE',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'categories/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}