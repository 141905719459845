import React from 'react'
import { Button } from '@chakra-ui/react'
import { Icon } from '.'
import { NavLink } from 'react-router-dom'

const NavButton = ({to, icon, label, showIcon, colorScheme, ...rest }) => {
    return(
        <Button 
            as={NavLink} 
            to={to} 
            fontWeight={showIcon ? "600" : "500"}
            {...(showIcon && {leftIcon: <Icon mr=".5" ml={1} fontSize="16px" name={icon} color="flat.green" />}) }            
            _active={{ bg: "transparent" }} 
            bg="transparent" 
            _hover={{ color: "gray.600",  textDecoration: 'none', _text:{textDecoration: 'none'}}} 
            borderLeft="3px solid transparent" 
            rounded="none" 
            color={showIcon ? "gray.700" : "gray.400"}
            fontSize="14px" 
            shadow="none" 
            w="100%"      
            h="36px"      
            justifyContent="flex-start" 
            textAlign="left" 
            pl={showIcon ? 3 : 9}
            className={`${"dash-nav "+ colorScheme || "" }`} 
            {...rest}
            > 
            {label} 
        </Button>
    )
}

export { NavButton }