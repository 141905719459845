import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const courseApi = {
    _get,    
    _list,
    _details,
    _add,
    _import_questions,
    _update,
    _delete,
};

async function _get() {    
        
    const requestOptions = {
        method: 'GET',        
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}

async function _list() {    

    const requestOptions = {
        method: 'GET',    
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses-list', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _details(slug) {        
    
    const requestOptions = {
        method: 'GET',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+slug, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _add(form_data) {
    
    const requestOptions = {
        method: 'POST',    
        headers: {            
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',            
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _import_questions(form_data, course_id) {
    
    const requestOptions = {
        method: 'POST',
        headers: {            
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',            
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/import-questions/'+course_id, requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _update(form_data, id) {

    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    
    const requestOptions = {
        method: 'DELETE',
        headers: {            
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}