import {handleResponse, requestTimeout} from '../utils'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

export const sectionApi = {
    _get,
    _sort_items,
    _add,
    _update,
    _delete,
};

async function _get(id) {    
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    ;
}

async function _sort_items(items) {    
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: JSON.stringify({items})      
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/reorder', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    ;
}


async function _add(form_data) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _update(form_data, id) {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(accessToken)
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}