import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PureAbility, AbilityBuilder } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'
import { authAction } from '../redux/actions'
import { useBoolean } from '@chakra-ui/react'

const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const  Ability = new PureAbility([]);
const Can = createCanBoundTo(Ability);

const initialState = {
    user: null,   
    token: localStorage.getItem(accessToken)
};

const AppContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),     
    logout: () => Promise.resolve(),
});

export const AppProvider = (props) => {
    
    const auth = useSelector(state => state.auth);        
    const [state, setState] = useState({...initialState})    
    const [ mount, setMount] = useBoolean()
    const [ loading, setLoading ] = useBoolean()    

    const dispatch = useDispatch()

    
    const login = (form_data) => {

        return new Promise(async (resolve, reject) => {            
            dispatch(authAction.login(form_data))
                .then((response) => {  
                    // setCookie(process.env.REACT_APP_AUTH_COOKIE, response?.token, { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax' })                  
                    resolve(response) 
                })
                .catch((error) => reject(error) )
            })

    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            dispatch(authAction.logout())        
                .then(() => {                        
                    resolve()
                })
                .catch((e) => {
                    reject()
                })
        })

    }

    
    

    useEffect(() => {
        const init = async () => {   
            setLoading.on()  
            
            dispatch(authAction.me())
                .then((res) => {
                    setMount.on()
                    setLoading.off()
                })
                .catch((err) => {
                    dispatch(authAction.clear())                        
                    setMount.on()            
                    setLoading.off()                    
                })
                
            // }else{
            //     removeCookie(process.env.REACT_APP_AUTH_COOKIE, true, { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax' })
            //     await dispatch(authAction.logout())
            //     setMount.on()            
            //     setLoading.off()
            // }
            // try{
                
            //     await dispatch(authAction.auth_check())                
            //     setMount.on()
            //     setLoading.off()
            // }
            // catch(err){                                
            //     console.log("Auth Error", err)
            //     setMount.on()
            //     setLoading.off()
            // }
        }
        if(!mount && !loading){
            init()                  
        }
        

    }, [mount, setMount, state, loading, setState, setLoading, dispatch])

    useEffect(() => {
        if(auth?.user && auth?.user?.permissions && auth?.user?.permissions?.length > 0){            
            const { can, rules } = new AbilityBuilder();
            let permissions = auth?.user?.permissions
            let actions = [];
            permissions?.map((permit, p) => {
                let permission = permit.split(' ')
                actions.push(permission[0])
                return can(permission[0], permission[1])                
            })
            Ability.update(rules);
        }

        if(auth?.user && auth?.user?.sa){
            const { can, rules } = new AbilityBuilder();
            can('manage', 'all');
            Ability.update(rules);    
        }
                
    }, [auth?.user])
    
    
    return (
        <AppContext.Provider
            value={{
                ...state,
                ...auth,
                loading,
                Ability,
                Can,
                setLoading,                
                login,
                logout
            }}>
            {props.children}
            
        </AppContext.Provider>
    );
}

export  {AppContext}